import React from 'react';
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Element } from 'react-scroll';
import scrollTo from '../asset/script/scrollTo';
import FramedImage from '../component/FramedImage';
import ImageDivider from '../component/ImageDivider';
import TextBlock from '../component/TextBlock';
import RouterLink from '../component/RouterLink';
import SEO from '../component/SEO';

import imgRubysCube from '../asset/image/rubysCube.jpg';
import patternOrangeContinuous from '../asset/image/patternOrangeContinuous.jpg';

import './Career.scss';

const Career = () => {
  const { i18n } = useTranslation();
  const t = i18n.getFixedT(null, 'career');

  return (
    <>
      <SEO
        title={t('SEO.title')}
        description={t('SEO.description')}
        keywords={t('SEO.keywords')}
      />
      <div className="career">
        <Container>
          <h2>{t('topline')}</h2>
          <h1>{t('headline')}</h1>
          <Row>
            <Col
              xl={{ span: 4, offset: 4 }}
              lg={{ span: 6, offset: 3 }}
              md={{ span: 8, offset: 2 }}
            >
              <TextBlock>
                <FramedImage src={imgRubysCube} />
                <TextBlock.Headline>
                  {t('weSolveProblems')}
                </TextBlock.Headline>
                <TextBlock.Outline>
                  {t('intro')}
                </TextBlock.Outline>
                <TextBlock.Button onClick={() => scrollTo('open-positions')}>
                  {t('seeOpenPositions')}
                </TextBlock.Button>
              </TextBlock>
            </Col>
          </Row>
        </Container>
        <ImageDivider src={patternOrangeContinuous} pattern />
        <Element name="open-positions">
          <Container>
            <h1>{t('openPositions')}</h1>
            <h2 className="department">Organisation</h2>
            <RouterLink to="mailto:info@k3b.de?subject=Bewerbung%20Werkstudent*in" className="job-offer">
              <Row>
                <Col xl={{ span: 6, offset: 3 }}>
                  <h3 className="job-title">{t('workingStudent')}</h3>
                  <div className="work-place">{t('berlin')}</div>
                  <hr />
                </Col>
              </Row>
            </RouterLink>
            <h2 className="department mt40">Tech</h2>
            <RouterLink to="https://drive.google.com/open?id=1kG1U_MkcXMLMShF2SJKsiVkAxD_iEBne" className="job-offer">
              <Row>
                <Col xl={{ span: 6, offset: 3 }}>
                  <h3 className="job-title">{t('seniorFullStackDeveloper')}</h3>
                  <div className="work-place">{`${t('remote')} / ${t('berlin')}`}</div>
                  <hr />
                </Col>
              </Row>
            </RouterLink>
            <RouterLink to="https://angel.co/company/k3b/jobs/463492-senior-software-engineer" className="job-offer">
              <Row>
                <Col xl={{ span: 6, offset: 3 }}>
                  <h3 className="job-title">{t('seniorSoftwareEngineer')}</h3>
                  <div className="work-place">{`${t('remote')} / ${t('berlin')}`}</div>
                  <hr />
                </Col>
              </Row>
            </RouterLink>
            {/*
            <RouterLink to="https://www.indeedjobs.com/k3b/_hl/de" className="job-offer">
              <Row>
                <Col xl={{ span: 6, offset: 3 }}>
                  <h3 className="job-title">{t('juniorSoftwareDeveloper')}</h3>
                  <div className="work-place">{`${t('remote')} / ${t('berlin')}`}</div>
                  <hr />
                </Col>
              </Row>
            </RouterLink>
            */}
          </Container>
        </Element>
      </div>
    </>
  );
};

export default Career;
