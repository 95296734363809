import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Picture.scss';

/*
needs custom webpack loader that provides both the original image and the webp file!
const webPImageLoader = {
  test: /\.(jpe?g|png)$/i,
  use: multiLoader(
    'file-loader?name=static/media/[name].webp!webp-loader?{quality: 90}',
    'file-loader?name=static/media/[name].[ext]',
  ),
};
*/

const Picture = ({ src, className, alt }) => {
  const classes = classnames('picture', className);
  const re = /\b\w{3,4}(?=$|\?)/;
  const ext = re.exec(src)[0];
  let type = 'image/';

  switch (ext) {
    case 'jpeg':
    case 'jpg':
      type += 'jpeg';
      break;
    case 'png':
      type += 'png';
      break;
    default:
      type += ext;
  }

  return (
    <picture className={classes}>
      <source srcSet={src.replace(ext, 'webp')} type="image/webp" />
      <source srcSet={src} type={type} />
      <img src={src} alt={alt} />
    </picture>
  );
};

Picture.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
};

Picture.defaultProps = {
  className: '',
  alt: '',
};

export default Picture;
