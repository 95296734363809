import Fingerprint2 from 'fingerprintjs2';

const generateFingerprint = () => new Promise(resolve => Fingerprint2.get(resolve));

const getFingerprint = async () => {
  const fingerprint = await generateFingerprint();
  const values = fingerprint.map(({ value }) => value);
  const hashedFingerprint = Fingerprint2.x64hash128(values.join(''), 31);
  return hashedFingerprint;
};

export default getFingerprint;
