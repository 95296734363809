import React from 'react';
import {
  Col,
  Container,
  Form,
  Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import classnames from 'classnames';
import * as yup from 'yup';
import { Element } from 'react-scroll';

import scrollTo from '../asset/script/scrollTo';
import ImageDivider from '../component/ImageDivider';
import Sanitize from '../component/Sanitize';
import SEO from '../component/SEO';

import patternOrangeContinuous from '../asset/image/patternOrangeContinuous.jpg';

import './Contact.scss';

const Contact = () => {
  const { i18n } = useTranslation();
  const t = i18n.getFixedT(null, 'contact');

  const GetInTouchForm = () => {
    const schema = yup.object({
      name: yup.string().required(),
      message: yup.string().required(),
      company: yup.string(),
      email: yup.string().email().required(),
    });

    const handleSumbit = (values, actions) => {
      const request = new XMLHttpRequest();
      request.open('POST', __CONTACT_API__, true);
      request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
      request.onreadystatechange = () => {
        if (request.readyState === 4) {
          if (request.status >= 200 && request.status < 400) {
            actions.setStatus({ submitted: true });
          } else {
            actions.setStatus({ error: request.responseText });
          }
        }
        actions.setSubmitting(false);
        scrollTo('feedback');
      };
      request.send(JSON.stringify(values));
    };

    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleSumbit}
        initialValues={{
          name: '',
          message: '',
          company: '',
          email: '',
        }}
        initialStatus={{
          error: null,
          submitted: false,
        }}
      >
        {({
          handleSubmit,
          values,
          touched,
          handleChange,
          errors,
          isSubmitting,
          status,
        }) => (
          <Form noValidate onSubmit={handleSubmit} className="mt40 mb40">
            <Element name="feedback">
              <Form.Row className={classnames('submission-feedback')}>
                {
                  status.submitted && (
                  <Form.Group
                    as={Col}
                    md={{ span: 8, offset: 2 }}
                    className={classnames('success-message')}
                  >
                    <Sanitize>
                      {t('thanksForYourMessage')}
                    </Sanitize>
                  </Form.Group>
                  )
                }
                {
                  !!status.error && (
                  <Form.Group
                    as={Col}
                    md={{ span: 8, offset: 2 }}
                    className={classnames('error-message')}
                  >
                    <Sanitize>
                      {t('somethingWentWrong')}
                    </Sanitize>
                  </Form.Group>
                  )
                }
                {
                /* honeypot for spambots
                // only bots can see this field
                // if it's provided, server should not process request
                */
                }
                <Form.Group
                  as={Col}
                  md={{ span: 3, offset: 2 }}
                  controlId="validationFormikContactMeByFaxOnly"
                  style={{ display: 'none' }}
                >
                  <Form.Label>Contact me by fax only</Form.Label>
                  <Form.Control
                    type="checkbox"
                    name="contact-me-by-phone"
                    tabIndex="-1"
                    autoComplete="off"
                    isValid
                    value={values.contactMeByFaxOnly}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form.Row>
            </Element>
            <Form.Row>
              <Form.Group as={Col} md={{ span: 3, offset: 2 }} controlId="validationFormikName">
                <Form.Label>{t('name.label')}</Form.Label>
                <Form.Control
                  disabled={status.submitted}
                  type="text"
                  name="name"
                  placeholder={t('name.placeholder')}
                  value={values.firstName}
                  onChange={handleChange}
                  isValid={touched.name && !errors.name}
                  isInvalid={touched.name && !!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {t('name.feedback')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="2" controlId="validationFormikCompany">
                <Form.Label>{t('company.label')}</Form.Label>
                <Form.Control
                  disabled={status.submitted}
                  type="text"
                  name="company"
                  placeholder={t('company.placeholder')}
                  value={values.company}
                  onChange={handleChange}
                  isValid={touched.company && !errors.company}
                />

                <Form.Control.Feedback type="invalid">
                  {t('company.feedback')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationFormikEmail">
                <Form.Label>{t('email.label')}</Form.Label>
                <Form.Control
                  disabled={status.submitted}
                  type="text"
                  placeholder={t('email.placeholder')}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isValid={touched.email && !errors.email}
                  isInvalid={touched.email && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {t('email.feedback')}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md={{ span: 8, offset: 2 }} controlId="validationFormikMessage">
                <Form.Label>{t('message.label')}</Form.Label>
                <Form.Control
                  disabled={status.submitted}
                  type="text"
                  as="textarea"
                  rows="3"
                  placeholder={t('message.placeholder')}
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  isInvalid={touched.message && !!errors.message}
                />

                <Form.Control.Feedback type="invalid">
                  {t('message.feedback')}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row className="mt40">
              <Form.Group as={Col} md={{ span: 8, offset: 2 }}>
                <Button
                  type="submit"
                  disabled={status.submitted}
                >
                  {(() => {
                    if (isSubmitting) { return t('submitting'); }
                    if (status.submitted) { return t('submitted'); }
                    return t('submit');
                  })()}
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <SEO
        title={t('SEO.title')}
        description={t('SEO.description')}
        keywords={t('SEO.keywords')}
      />
      <div className="contact">
        <Container>
          <h2>{t('headline')}</h2>
          <h3>{t('subline')}</h3>
          <GetInTouchForm />
        </Container>
        <ImageDivider src={patternOrangeContinuous} pattern />
      </div>
    </>
  );
};

export default Contact;
