const webPtest = new Promise((resolve, reject) => {
  const i = new Image();
  i.onload = resolve;
  i.onerror = reject;
  i.src = 'data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==';
});

export default async function () {
  try {
    await webPtest;
    return true;
  } catch (error) {
    return false;
  }
}
