import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Statement.scss';
import Sanitize from './Sanitize';

const Quotation = ({ children }) => (
  <div className="quotation">
    <hr className="line top" />
    <div className="yellow-box" />
    <div className="quote-symbol">
    ”
    </div>
    <h3 className="quote">
      <Sanitize>
        {children}
      </Sanitize>
    </h3>
    <hr className="line bottom" />
  </div>
);

Quotation.propTypes = {
  children: PropTypes.node.isRequired,
};

const Source = ({ children }) => (
  <div className="source">
    <Sanitize>
      {children}
    </Sanitize>
  </div>
);

Source.propTypes = {
  children: PropTypes.node.isRequired,
};

Quotation.displayName = 'Quotation';
Source.displayName = 'Source';

class Statement extends Component {
  static Quotation = Quotation;

  static Source = Source;

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;
    return (
      <div className="statement">
        {children}
      </div>
    );
  }
}

export default Statement;
