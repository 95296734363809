import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button as BsButton } from 'react-bootstrap';

import Sanitize from './Sanitize';
import FramedImage from './FramedImage';
import './TextBlock.scss';

const Copy = ({ children }) => (
  <div className="copy">
    <Sanitize>
      {children}
    </Sanitize>
  </div>
);

Copy.propTypes = {
  children: PropTypes.node.isRequired,
};

const Headline = ({ children }) => (
  <h2 className="headline">
    {children}
  </h2>
);

Headline.propTypes = {
  children: PropTypes.node.isRequired,
};

const Button = ({ onClick, className, children }) => (
  <div className={classnames('button', className)}>
    <BsButton bsPrefix="k3b" onClick={onClick}>
      <Sanitize>
        {children}
      </Sanitize>
    </BsButton>
  </div>
);

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Button.defaultProps = {
  className: null,
};

const Outline = ({ children, className }) => (
  <div className={classnames('outline', className)}>
    <Sanitize>
      {children}
    </Sanitize>
  </div>
);


Outline.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Outline.defaultProps = {
  className: '',
};

const Icon = ({ src }) => (
  <FramedImage src={src} icon />
);

Icon.propTypes = {
  src: PropTypes.string.isRequired,
};

Copy.displayName = 'Copy';
Headline.displayName = 'Headline';
Button.displayName = 'Button';
Outline.displayName = 'Outline';
Icon.displayName = 'Icon';

class TextBlock extends Component {
  static Headline = Headline;

  static Copy = Copy;

  static Button = Button;

  static Outline = Outline;

  static Icon = Icon;

  static propTypes = {
    children: PropTypes.node.isRequired,
    align: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    align: 'center',
    className: '',
  };

  render() {
    const { children, align, className } = this.props;
    return (
      <div className={classnames('text-block', align, className)}>
        {children}
      </div>
    );
  }
}

export default TextBlock;
