import React from 'react';
import {
  Route,
  Router,
  Redirect,
  Switch,
} from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import 'requestidlecallback-polyfill';

import Career from './Career';
import Contact from './Contact';
import Imprint from './Imprint';
import Header from './Header';
import Footer from './Footer';

import SEO from '../component/SEO';

import Home from './Home';
import AssertValidLocale from '../component/AssertValidLocale';
import ScrollToTop from '../component/ScrollToTop';

import i18n from '../asset/script/i18n';
import getFingerprint from '../asset/script/getFingerprint';
import imgOGK3bLogo from '../asset/image/ogK3bLogo.png';

import './App.scss';

// setup tracking with Google Analytics
// using a browser fingerprint instead of a cookie
requestIdleCallback(async () => {
  const clientFingerprint = await getFingerprint();
  ReactGA.initialize(
    'UA-146764660-1', {
      gaOptions: {
        storage: 'none',
        clientId: clientFingerprint,
      },
    },
  );
  ReactGA.set({ anonymizeIp: true });
  ReactGA.pageview(window.location.pathname + window.location.search);
});
const history = createBrowserHistory();
// TODO handle history events a bit smarter
// each language change is also triggered as a pageview
history.listen(location => ReactGA.pageview(location.pathname + location.search));

const App = () => (
  <div className="app">
    <I18nextProvider i18n={i18n}>
      <Router history={history}>
        <>
          <ScrollToTop />
          <Switch>
            <Route path="/" component={AssertValidLocale} exact />
            <Route path="/:lang" component={AssertValidLocale} />
          </Switch>
          <Route
            path="/:lang"
            render={() => (
              <>
                <SEO
                  title="k3b"
                  description="k3b GmbH"
                  keywords="Software Engineering, Software, Software Solutions, Software Service Providers"
                  image={{
                    url: imgOGK3bLogo,
                    alt: 'k3b Logo',
                    height: '300',
                    width: '300',
                  }}
                />
                <Header />
                <Switch>
                  <Route path="/:lang" component={Home} exact />
                  <Route path="/:lang/jobs" component={Career} exact />
                  <Route path="/:lang/contact" component={Contact} exact />
                  <Route path="/:lang/imprint" component={Imprint} exact />
                  <Redirect from="/:lang" to="/:lang" />
                </Switch>
                <Footer>
                  <Switch>
                    <Route path="/:lang" component={Home.Footer} exact />
                  </Switch>
                </Footer>
              </>
            )}
          />
        </>
      </Router>
    </I18nextProvider>
  </div>
);

export default App;
