import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import RouterLink from './RouterLink';
import testWebpSupport from '../asset/script/testWebpSupport';

import triangle from '../asset/image/triangle.jpg';
import './TopCorner.scss';

const TopCorner = ({
  match: {
    params: {
      lang,
    },
  },
}) => {
  // TODO move this webp loading into lib
  // it's also used with Image Divider
  const [imgSrc, setImgSrc] = useState(null);
  useEffect(() => {
    const test = async () => {
      const src = triangle;
      const webpSupport = await testWebpSupport();
      const re = /\b\w{3,4}(?=$|\?)/;
      const ext = re.exec(src)[0];
      setImgSrc(webpSupport ? src.replace(ext, 'webp') : src);
    };
    test();
  }, [triangle]);

  const backgroundStyle = {
    backgroundImage: imgSrc && `url(${imgSrc})`,
    backgroundPosition: 'top 533px right -409px',
  };

  return (
    <Container fluid className="top-corner-container">
      <div className="triangle" style={backgroundStyle} />
      <RouterLink className="logo" to={`/${lang}/`} />
    </Container>
  );
};

TopCorner.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default withRouter(TopCorner);
