import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import i18n from '../asset/script/i18n';

const SEO = ({
  title,
  description,
  keywords,
  image,
  type,
  location: { pathname },
  match: { params: { lang } },
}) => {
  // __PUBLIC_URL__ needs to be defined via Webpack.DefinePlugin
  const url = `${__BASE_URL__}${pathname}`;
  const imageUrl = image ? `${__BASE_URL__}${image.url || image}` : '';

  const metaTags = [
    { name: 'description', content: description },
    { name: 'keywords', content: keywords },

    { property: 'og:url', content: url },
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
    { property: 'og:site_name', content: 'k3b' },
    { property: 'og:type', content: type },
    { property: 'og:image', content: imageUrl },

    { name: 'og:image:url', content: imageUrl },
    { name: 'og:image:secure_url', content: imageUrl.replace('http://', 'https://') },
    { name: 'og:image:type', content: image.type },
    { name: 'og:image:width', content: image.width },
    { name: 'og:image:height', content: image.height },
    { name: 'og:image:alt', content: image.alt },

    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:url', content: url },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: imageUrl },
  ].filter(({ content }) => content && content.length);

  const { options: { resources } } = i18n;
  const availableLanguages = Object.keys(resources);
  const alternateLanguageLinks = availableLanguages.map(availableLanguage => ({
    rel: 'alternate',
    href: url.replace(lang, availableLanguage),
    hrefLang: availableLanguage,
  }));

  return (
    <Helmet
      title={title}
      meta={metaTags}
      link={[
        { rel: 'canonical', href: url },
      ].concat(alternateLanguageLinks)}
      htmlAttributes={{
        lang,
      }}
    />
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      link: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
    }),
  ]),
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
};

SEO.defaultProps = {
  title: null,
  description: null,
  keywords: null,
  image: '',
  type: 'website',
};

export default withRouter(SEO);
