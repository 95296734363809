import i18n from 'i18next';
// LanguageDetection is done through React
// import LanguageDetector from 'i18next-browser-languagedetector';

import commonEN from '../locale/common.en.json';
import commonDE from '../locale/common.de.json';
import homeEN from '../locale/home.en.json';
import homeDE from '../locale/home.de.json';
import careerEN from '../locale/career.en.json';
import careerDE from '../locale/career.de.json';
import imprintEN from '../locale/imprint.en.json';
import imprintDE from '../locale/imprint.de.json';
import contactEN from '../locale/contact.en.json';
import contactDE from '../locale/contact.de.json';

i18n
// .use(LanguageDetector)
  .init({
    // 'detection' is the LanguageDetector Options
    // see https://github.com/i18next/i18next-browser-languageDetector/blob/master/README.md#detector-options
    // detection: {
    //  order: ['querystring', 'navigator', 'htmlTag', 'path', 'subdomain'],
    //  caches: []
    // },
    resources: {
      de: {
        common: commonDE,
        home: homeDE,
        career: careerDE,
        imprint: imprintDE,
        contact: contactDE,
      },
      en: {
        common: commonEN,
        home: homeEN,
        career: careerEN,
        imprint: imprintEN,
        contact: contactEN,
      },
    },
    caches: [],
    fallbackLng: 'de', // use en if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    ns: ['common', 'home', 'career', 'imprint'],
    defaultNS: 'common',
    useCookie: false,
    useLocalStorage: false,
  });

export default i18n;
