import {
  scroller as scroll,
  animateScroll,
} from 'react-scroll';

// https://github.com/fisshy/react-scroll#scroll-animations
const DEFAULT_OPTIONS = {
  duration: 1500,
  smooth: 'easeOutQuint',
};

const scrollToTop = () => animateScroll.scrollToTop(DEFAULT_OPTIONS);
const scrollTo = (target, options) => {
  scroll.scrollTo(target, Object.assign({}, DEFAULT_OPTIONS, options));
};

export {
  scrollToTop,
  scrollTo as default,
};
