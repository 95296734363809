import React from 'react';
import {
  Redirect,
  withRouter,
} from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import i18n from '../asset/script/i18n';

const AssertValidLocale = (props) => {
  const {
    location: { pathname },
    match: { params: { lang } },
  } = props;

  const {
    options: {
      resources,
      fallbackLng,
    },
  } = i18n;

  const availableLocales = Object.keys(resources);
  const isValid = testLocale => testLocale && availableLocales.includes(testLocale);
  const browserLocale = (navigator.language || navigator.userLanguage).split('-')[0];

  const defaultLocale = isValid(browserLocale)
    ? browserLocale
    : fallbackLng[0] || availableLocales[0];
  const locale = isValid(lang) ? lang : defaultLocale;

  const splittedPathname = pathname.split('/');
  splittedPathname.splice(1, 1, locale);
  const url = splittedPathname.join('/');

  if (i18n.language !== locale) { i18n.changeLanguage(locale); }
  // e.g /jobs will be redirected to /en/jobs
  if (url !== pathname) {
    return <Redirect to={`${url}${pathname}`} />;
  }
  return null;
};

AssertValidLocale.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

export default withRouter(AssertValidLocale);
