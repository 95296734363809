import React, { Component } from 'react';
import {
  Button,
  Container,
  Nav,
  Row,
} from 'react-bootstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';

import RouterLink from './RouterLink';
import i18n from '../asset/script/i18n';

import './NavMenu.scss';

const LanguageSelector = ({
  history,
  location: {
    pathname,
  },
  match: {
    params: {
      lang,
    },
  },
}) => {
  const { options: { resources } } = i18n;

  const availableLanguages = Object.keys(resources);
  // do not show anything if there is no choice for different languages
  if (availableLanguages.length < 2) return null;

  const languageSelector = [];

  const changeLanguage = (lng) => {
    history.push(`/${lng}/${pathname.substr(4)}`);
  };

  availableLanguages.forEach((language) => {
    const upperCaseLanguage = language.toUpperCase();
    languageSelector.push(
      <Button
        bsPrefix={classnames('language-button', { active: lang === language })}
        onClick={() => changeLanguage(language)}
        key={language}
      >
        {upperCaseLanguage}
      </Button>,
    );
  });
  return languageSelector;
};

const Item = ({
  label,
  url,
  onClick,
  match: {
    params: {
      lang,
    },
  },
}) => {
  const item = (
    <>
      <span className="first-character">
        {label[0]}
      </span>
      <span className="remaining-characters">
        {label.substr(1)}
      </span>
    </>
  );

  return (
    <Nav.Item>
      {onClick ? (
        <button
          type="button"
          onClick={onClick}
          className="link"
        >
          {item}
        </button>
      ) : (
        <RouterLink to={`/${lang}/${url}`} className="link">
          {item}
        </RouterLink>
      )}
    </Nav.Item>
  );
};

Item.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
  match: ReactRouterPropTypes.match.isRequired,
  onClick: PropTypes.func,
};

Item.defaultProps = {
  url: null,
  onClick: null,
};

const TopLineItem = ({ label, url }) => (
  <div className="top-line-item">
    <RouterLink
      className="item"
      to={url}
    >
      {label}
    </RouterLink>
  </div>
);

TopLineItem.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

class NavMenu extends Component {
  static TopLineItem = withRouter(TopLineItem);

  static Item = withRouter(Item);

  static LanguageSelector = withRouter(LanguageSelector);

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const {
      children,
    } = this.props;

    const topLineItems = [];
    const items = [];
    const languageSelector = [];

    const childrenArray = Array.isArray(children) ? children : [children];
    childrenArray.forEach((child) => {
      if (child.type === NavMenu.TopLineItem) {
        topLineItems.push(child);
      } else if (child.type === NavMenu.LanguageSelector) {
        languageSelector.push(child);
      } else {
        items.push(child);
      }
    });

    return (
      <div className="nav-menu">
        <Container>
          <Row>
            {languageSelector}
          </Row>
        </Container>
        <Container className="navs">
          <Row className="top-line-items">
            {topLineItems}
          </Row>
          <Row>
            {items}
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(NavMenu);
