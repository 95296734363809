import React from 'react';
import { useTranslation } from 'react-i18next';
import NavMenu from '../component/NavMenu';
import TopCorner from '../component/TopCorner';

const Header = () => {
  const { t } = useTranslation();
  return (
    <>
      <TopCorner />
      <NavMenu>
        <NavMenu.LanguageSelector />
        {/*
        <NavMenu.TopLineItem label="0 30 / 3 33 33 33" url="tel:+4930333333" />
        */}
        <NavMenu.TopLineItem label="info@k3b.de" url="mailto:info@k3b.de" />
        <NavMenu.Item label={t('navmenu.home')} url="" />
        <NavMenu.Item label={t('navmenu.jobs')} url="jobs" />
        <NavMenu.Item label={t('navmenu.contact')} url="contact" />
      </NavMenu>
    </>
  );
};

export default Header;
