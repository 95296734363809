import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const RouterLink = ({
  to,
  href,
  className,
  children,
  title,
}) => {
  const url = to || href;
  return (
    url.match(/^\//)
      ? <Link to={url} className={className}>{children}</Link>
      : (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={url}
          className={className}
          title={title}
        >
          {children}
        </a>
      )
  );
};

RouterLink.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

RouterLink.defaultProps = {
  to: null,
  href: null,
  title: '',
  className: '',
  children: null,
};

export default RouterLink;
