import PropTypes from 'prop-types';
import React from 'react';
import SanitizedHTML from 'react-sanitized-html';

const Sanitize = ({ children }) => {
  const childrenArray = Array.isArray(children) ? children : [children];
  return (
    <>
      {childrenArray.map((child) => {
        if (typeof (child) === 'string') {
          return <SanitizedHTML className="sanitized" html={child} key={`${child}`} />;
        }
        return child;
      })}
    </>
  );
};

Sanitize.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Sanitize;
