import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Picture from './Picture';

import './FramedImage.scss';

const FramedImage = ({
  src,
  icon,
  alt,
  className,
}) => (
  <div className="framed-image">
    <div className={classnames('image', 'with-border-edges', className, { icon })}>
      <Picture src={src} alt={alt} />
    </div>
  </div>
);


FramedImage.propTypes = {
  src: PropTypes.string.isRequired,
  icon: PropTypes.bool,
  alt: PropTypes.string,
  className: PropTypes.string,
};

FramedImage.defaultProps = {
  icon: false,
  alt: 'a nice image goes here',
  className: '',
};

export default FramedImage;
