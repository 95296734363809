import { withRouter } from 'react-router-dom';
import { scrollToTop } from '../asset/script/scrollTo';
import useUpdateEffect from '../asset/script/useUpdateEffect';

// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
const ScrollToTop = ({ children, location: { pathname } }) => {
  useUpdateEffect(() => {
    scrollToTop();
  }, [pathname]);

  return children || null;
};

export default withRouter(ScrollToTop);
