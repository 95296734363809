import React from 'react';
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ImageDivider from '../component/ImageDivider';
import Sanitize from '../component/Sanitize';
import SEO from '../component/SEO';

import patternBlueContinuous from '../asset/image/patternBlueContinuous.jpg';


import './Imprint.scss';

const Imprint = () => {
  const { i18n } = useTranslation();
  const t = i18n.getFixedT(null, 'imprint');

  return (
    <>
      <SEO
        title={t('SEO.title')}
        description={t('SEO.description')}
        keywords={t('SEO.keywords')}
      />
      <div className="imprint">
        <Container>
          <h2>{t('imprint')}</h2>
          <Row>
            <Col>
              {t('accordingToParagraph')}
            </Col>
          </Row>
          <Row className="company-address mb40">
            <Col>
              <Sanitize>
                {t('address')}
              </Sanitize>
            </Col>
          </Row>
        </Container>
        <ImageDivider src={patternBlueContinuous} pattern />
        <Container>
          <h3>{t('disclaimer.headline')}</h3>
          <h4>{t('disclaimer.content.headline')}</h4>
          <Row className="disclaimer-content mb40">
            <Col>
              <Sanitize>
                {t('disclaimer.content.body')}
              </Sanitize>
            </Col>
          </Row>
          <h4>{t('disclaimer.links.headline')}</h4>
          <Row className="disclaimer-links mb40">
            <Col>
              <Sanitize>
                {t('disclaimer.links.body')}
              </Sanitize>
            </Col>
          </Row>
          <h4>Google Analytics</h4>
          <Row className="disclaimer-google-analytics">
            <Col>
              <Sanitize>
                {t('disclaimer.googleAnalytics')}
              </Sanitize>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Imprint;
