import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Container } from 'react-bootstrap';
import testWebpSupport from '../asset/script/testWebpSupport';

import './ImageDivider.scss';

/*
needs custom webpack loader that provides both the original image and the webp file!
const webPImageLoader = {
  test: /\.(jpe?g|png)$/i,
  use: multiLoader(
    'file-loader?name=static/media/[name].webp!webp-loader?{quality: 90}',
    'file-loader?name=static/media/[name].[ext]',
  ),
};
*/
const ImageDivider = ({
  src,
  pattern,
  style,
  className,
}) => {
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    const test = async () => {
      const webpSupport = await testWebpSupport();
      const re = /\b\w{3,4}(?=$|\?)/;
      const ext = re.exec(src)[0];
      setImgSrc(webpSupport ? src.replace(ext, 'webp') : src);
    };
    test();
  }, [src]);

  const backgroundStyle = {
    backgroundImage: imgSrc && `url(${imgSrc})`,
    backgroundPosition: style.backgroundPosition,
  };

  return (
    <Container fluid className="image-divider-container">
      <div className={classnames('image-divider', { pattern }, className)} style={backgroundStyle} />
    </Container>
  );
};

ImageDivider.propTypes = {
  className: PropTypes.string,
  pattern: PropTypes.bool,
  src: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.string),
};

ImageDivider.defaultProps = {
  className: '',
  pattern: false,
  style: { backgroundPosition: 'center' },
};

export default ImageDivider;
