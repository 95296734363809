import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactSVG from 'react-svg';

import './FramedSVG.scss';

// TODO check if further ReactSVG props are useful: https://github.com/tanem/react-svg
const FramedSVG = ({ src, className, icon }) => (
  <div className="framed-svg">
    <div className={classnames('svg', 'with-border-edges', className, { icon })}>
      <ReactSVG src={src} />
    </div>
  </div>
);


FramedSVG.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.bool,
};

FramedSVG.defaultProps = {
  className: '',
  icon: false,
};

export default FramedSVG;
