import React from 'react';
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import scrollTo from '../asset/script/scrollTo';
import FramedImage from '../component/FramedImage';
import FramedSVG from '../component/FramedSVG';
import ImageDivider from '../component/ImageDivider';
import Statement from '../component/Statement';
import TextBlock from '../component/TextBlock';
import RouterLink from '../component/RouterLink';
import SEO from '../component/SEO';

// img
import imgPredictiveMaintenance from '../asset/image/predictiveMaintenance.jpg';
import imgAudioVideoProcessing from '../asset/image/tvRemoteSmartTv.jpg';
import imgBusinessIntelligence from '../asset/image/businessIntelligence.jpg';
import imgStromPi from '../asset/image/stromPi.jpg';
import imgAdSpendingForecast from '../asset/image/adSpendingForecast.jpg';
import imgKIS from '../asset/image/kis.jpg';
import imgTvTracker from '../asset/image/tvTracker.jpg';
import problemSolvingOrange from '../asset/image/problemSolvingOrange.jpg';
import patternBlueContinuous from '../asset/image/patternBlueContinuous.jpg';

// svg
import iconLift from '../asset/image/lift.svg';
import iconMedicalRecord from '../asset/image/medical-record.svg';
import iconPlug from '../asset/image/plug.svg';
import iconData from '../asset/image/data.svg';
import iconTetris from '../asset/image/parquet.svg';
import iconSiren from '../asset/image/siren.svg';
import iconTV from '../asset/image/television.svg';
import iconFingerprint from '../asset/image/fingerprint.svg';
import iconHospital from '../asset/image/hospital.svg';
import iconFire from '../asset/image/fire.svg';
import iconNetwork from '../asset/image/blockchain.svg';
import iconAI from '../asset/image/artificial-intelligence.svg';
import iconInnovation from '../asset/image/innovation.svg';
import iconChip from '../asset/image/chip.svg';
import iconRocket from '../asset/image/startup.svg';


import './Home.scss';

const Home = () => {
  const { i18n } = useTranslation();
  const t = i18n.getFixedT(null, 'home');
  return (
    <>
      <SEO
        title={t('SEO.title')}
        description={t('SEO.description')}
        keywords={t('SEO.keywords')}
      />
      <div className="home">
        <Container className="mb40">
          <h1>{t('headline')}</h1>
          <Row className="services">
            <Col xl={4} lg={4} md={4}>
              <TextBlock className="service">
                <FramedSVG
                  className="icon"
                  src={iconAI}
                />
                <TextBlock.Headline>
                  {t('services.service1.headline')}
                </TextBlock.Headline>
                <TextBlock.Outline>
                  {t('services.service1.paragraph')}
                </TextBlock.Outline>
                <TextBlock.Button onClick={() => scrollTo('predictive-maintenance')}>
                  {t('services.cta')}
                </TextBlock.Button>
              </TextBlock>
            </Col>
            <Col xl={4} lg={4} md={4}>
              <TextBlock className="service">
                <FramedSVG
                  className="icon"
                  src={iconInnovation}
                />
                <TextBlock.Headline>
                  {t('services.service2.headline')}
                </TextBlock.Headline>
                <TextBlock.Outline>
                  {t('services.service2.paragraph')}
                </TextBlock.Outline>
                <TextBlock.Button onClick={() => scrollTo('business-intelligence')}>
                  {t('services.cta')}
                </TextBlock.Button>
              </TextBlock>
            </Col>
            <Col xl={4} lg={4} md={4}>
              <TextBlock className="service">
                <FramedSVG
                  className="icon"
                  src={iconChip}
                />
                <TextBlock.Headline>
                  {t('services.service3.headline')}
                </TextBlock.Headline>
                <TextBlock.Outline>
                  {t('services.service3.paragraph')}
                </TextBlock.Outline>
                <TextBlock.Button onClick={() => scrollTo('showcases')}>
                  {t('services.cta')}
                </TextBlock.Button>
              </TextBlock>
            </Col>
          </Row>
        </Container>
        <Container className="mb40">
          <Row>
            <Col xl={12} lg={12} md={12}>
              <Statement>
                <Statement.Quotation>
                  {t('statement.quotation')}
                </Statement.Quotation>
                <Statement.Source>
                  {t('statement.source')}
                </Statement.Source>
              </Statement>
            </Col>
          </Row>
        </Container>
        <Container className="benefits mb40">
          <Row>
            <Col xl={6} lg={6} md={6}>
              <TextBlock>
                <TextBlock.Headline>
                  {t('benefits.headline1')}
                </TextBlock.Headline>
                <TextBlock.Copy>
                  {t('benefits.body1')}
                </TextBlock.Copy>
              </TextBlock>
            </Col>
            <Col>
              <TextBlock>
                <TextBlock.Headline>
                  {t('benefits.headline2')}
                </TextBlock.Headline>
                <TextBlock.Copy>
                  {t('benefits.body2')}
                </TextBlock.Copy>
              </TextBlock>
            </Col>
          </Row>
        </Container>
        <Element name="get-in-touch">
          <Container className="mb40 get-in-touch">
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <TextBlock className="cta">
                  <FramedSVG
                    className="rocket-icon"
                    src={iconRocket}
                  />
                  <h3 className="">{t('getInTouch.headline')}</h3>
                  <TextBlock.Outline>
                    {t('getInTouch.cta')}
                  </TextBlock.Outline>
                  <h1 className="email">
                    <RouterLink className="email" to="mailto:info@k3b.de">
                      info@k3b.de
                    </RouterLink>
                  </h1>
                </TextBlock>
              </Col>
            </Row>
          </Container>
        </Element>
        <ImageDivider src={problemSolvingOrange} pattern className="orange-pattern" />
        <Container className="mb40 skillset">
          <h1>{t('skillset.headline')}</h1>
          <Element name="predictive-maintenance">
            <Row className="skill">
              <Col xl={4} lg={4} md={4}>
                <FramedImage src={imgPredictiveMaintenance} />
              </Col>
              <Col xl={8} lg={8} md={8}>
                <TextBlock align="left">
                  <TextBlock.Headline>
                    {t('skillset.predictiveMaintenance.headline')}
                  </TextBlock.Headline>
                  <TextBlock.Copy>
                    {t('skillset.predictiveMaintenance.body')}
                  </TextBlock.Copy>
                </TextBlock>
              </Col>
            </Row>
          </Element>
          <Row className="skill">
            <Col xl={4} lg={4} md={{ span: 4, order: 12 }}>
              <FramedImage src={imgAudioVideoProcessing} />
            </Col>
            <Col xl={8} lg={8} md={{ span: 8, order: 1 }}>
              <TextBlock align="left">
                <TextBlock.Headline>
                  {t('skillset.audioVideoProcessing.headline')}
                </TextBlock.Headline>
                <TextBlock.Copy>
                  {t('skillset.audioVideoProcessing.body')}
                </TextBlock.Copy>
              </TextBlock>
            </Col>
          </Row>
          <Element name="business-intelligence">
            <Row className="skill">
              <Col xl={4} lg={4} md={4}>
                <FramedImage src={imgBusinessIntelligence} />
              </Col>
              <Col xl={8} lg={8} md={8}>
                <TextBlock align="left">
                  <TextBlock.Headline>
                    {t('skillset.businessIntelligence.headline')}
                  </TextBlock.Headline>
                  <TextBlock.Copy>
                    {t('skillset.businessIntelligence.body')}
                  </TextBlock.Copy>
                </TextBlock>
              </Col>
            </Row>
          </Element>
        </Container>
        <ImageDivider src={patternBlueContinuous} pattern />
        <Element name="showcases">
          <Container className="mb40 showcases">
            <h1>{t('showcase.headline')}</h1>
            <Row className="mb80">
              <Col xl={4} lg={4} md={4}>
                <FramedImage className="mt20" src={imgStromPi} />
              </Col>
              <Col xl={8} lg={8} md={8}>
                <TextBlock align="left">
                  <TextBlock.Headline className="feature-headline">
                    {t('showcase.elevatorBox.headline')}
                  </TextBlock.Headline>
                </TextBlock>
                <Row>
                  <Col xl={2} lg={2} md={2} xs={3}>
                    <TextBlock align="left">
                      <FramedSVG
                        className="icon"
                        src={iconLift}
                      />
                    </TextBlock>
                  </Col>
                  <Col xl={10} lg={10} md={10} xs={9}>
                    <TextBlock align="left">
                      <TextBlock.Outline className="feature-headline">
                        {t('showcase.elevatorBox.feature1.headline')}
                      </TextBlock.Outline>
                      <TextBlock.Copy>
                        {t('showcase.elevatorBox.feature1.body')}
                      </TextBlock.Copy>
                    </TextBlock>
                  </Col>
                </Row>
                <Row>
                  <Col xl={2} lg={2} md={2} xs={3}>
                    <TextBlock align="left">
                      <FramedSVG
                        className="icon"
                        src={iconMedicalRecord}
                      />
                    </TextBlock>
                  </Col>
                  <Col xl={10} lg={10} md={10} xs={9}>
                    <TextBlock align="left">
                      <TextBlock.Outline className="feature-headline">
                        {t('showcase.elevatorBox.feature2.headline')}
                      </TextBlock.Outline>
                      <TextBlock.Copy>
                        {t('showcase.elevatorBox.feature2.body')}
                      </TextBlock.Copy>
                    </TextBlock>
                  </Col>
                </Row>
                <Row>
                  <Col xl={2} lg={2} md={2} xs={3}>
                    <TextBlock align="left">
                      <FramedSVG
                        className="icon"
                        src={iconPlug}
                      />
                    </TextBlock>
                  </Col>
                  <Col xl={10} lg={10} md={10} xs={9}>
                    <TextBlock align="left">
                      <TextBlock.Outline className="feature-headline">
                        {t('showcase.elevatorBox.feature3.headline')}
                      </TextBlock.Outline>
                      <TextBlock.Copy>
                        {t('showcase.elevatorBox.feature3.body')}
                      </TextBlock.Copy>
                    </TextBlock>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb80">
              <Col xl={4} lg={4} md={4}>
                <FramedImage className="mt20" src={imgAdSpendingForecast} />
              </Col>
              <Col xl={8} lg={8} md={8}>
                <TextBlock align="left">
                  <TextBlock.Headline className="feature-headline">
                    {t('showcase.adSpendingForecast.headline')}
                  </TextBlock.Headline>
                </TextBlock>
                <Row>
                  <Col xl={2} lg={2} md={2} xs={3}>
                    <TextBlock align="left">
                      <FramedSVG
                        className="icon"
                        src={iconData}
                      />
                    </TextBlock>
                  </Col>
                  <Col xl={10} lg={10} md={10} xs={9}>
                    <TextBlock align="left">
                      <TextBlock.Outline className="feature-headline">
                        {t('showcase.adSpendingForecast.feature1.headline')}
                      </TextBlock.Outline>
                      <TextBlock.Copy>
                        {t('showcase.adSpendingForecast.feature1.body')}
                      </TextBlock.Copy>
                    </TextBlock>
                  </Col>
                </Row>
                <Row>
                  <Col xl={2} lg={2} md={2} xs={3}>
                    <TextBlock align="left">
                      <FramedSVG
                        className="icon"
                        src={iconTetris}
                      />
                    </TextBlock>
                  </Col>
                  <Col xl={10} lg={10} md={10} xs={9}>
                    <TextBlock align="left">
                      <TextBlock.Outline className="feature-headline">
                        {t('showcase.adSpendingForecast.feature2.headline')}
                      </TextBlock.Outline>
                      <TextBlock.Copy>
                        {t('showcase.adSpendingForecast.feature2.body')}
                      </TextBlock.Copy>
                    </TextBlock>
                  </Col>
                </Row>
                <Row>
                  <Col xl={2} lg={2} md={2} xs={3}>
                    <TextBlock align="left">
                      <FramedSVG
                        className="icon"
                        src={iconSiren}
                      />
                    </TextBlock>
                  </Col>
                  <Col xl={10} lg={10} md={10} xs={9}>
                    <TextBlock align="left">
                      <TextBlock.Outline className="feature-headline">
                        {t('showcase.adSpendingForecast.feature3.headline')}
                      </TextBlock.Outline>
                      <TextBlock.Copy>
                        {t('showcase.adSpendingForecast.feature3.body')}
                      </TextBlock.Copy>
                    </TextBlock>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb80">
              <Col xl={4} lg={4} md={4}>
                <FramedImage className="mt20" src={imgTvTracker} />
              </Col>
              <Col xl={8} lg={8} md={8}>
                <TextBlock align="left">
                  <TextBlock.Headline className="feature-headline">
                    {t('showcase.tvTracker.headline')}
                  </TextBlock.Headline>
                </TextBlock>
                <Row>
                  <Col xl={2} lg={2} md={2} xs={3}>
                    <TextBlock align="left">
                      <FramedSVG
                        className="icon"
                        src={iconTV}
                      />
                    </TextBlock>
                  </Col>
                  <Col xl={10} lg={10} md={10} xs={9}>
                    <TextBlock align="left">
                      <TextBlock.Outline className="feature-headline">
                        {t('showcase.tvTracker.feature1.headline')}
                      </TextBlock.Outline>
                      <TextBlock.Copy>
                        {t('showcase.tvTracker.feature1.body')}
                      </TextBlock.Copy>
                    </TextBlock>
                  </Col>
                </Row>
                <Row>
                  <Col xl={2} lg={2} md={2} xs={3}>
                    <TextBlock align="left">
                      <FramedSVG
                        className="icon"
                        src={iconFingerprint}
                      />
                    </TextBlock>
                  </Col>
                  <Col xl={10} lg={10} md={10} xs={9}>
                    <TextBlock align="left">
                      <TextBlock.Outline className="feature-headline">
                        {t('showcase.tvTracker.feature2.headline')}
                      </TextBlock.Outline>
                      <TextBlock.Copy>
                        {t('showcase.tvTracker.feature2.body')}
                      </TextBlock.Copy>
                    </TextBlock>
                  </Col>
                </Row>
                <Row>
                  <Col xl={2} lg={2} md={2} xs={3}>
                    <TextBlock align="left">
                      <FramedSVG
                        className="icon"
                        src={iconSiren}
                      />
                    </TextBlock>
                  </Col>
                  <Col xl={10} lg={10} md={10} xs={9}>
                    <TextBlock align="left">
                      <TextBlock.Outline className="feature-headline">
                        {t('showcase.tvTracker.feature3.headline')}
                      </TextBlock.Outline>
                      <TextBlock.Copy>
                        {t('showcase.tvTracker.feature3.body')}
                      </TextBlock.Copy>
                    </TextBlock>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb80">
              <Col xl={4} lg={4} md={4}>
                <FramedImage className="mt20" src={imgKIS} />
              </Col>
              <Col xl={8} lg={8} md={8}>
                <TextBlock align="left">
                  <TextBlock.Headline className="feature-headline">
                    {t('showcase.KIS.headline')}
                  </TextBlock.Headline>
                </TextBlock>
                <Row>
                  <Col xl={2} lg={2} md={2} xs={3}>
                    <TextBlock align="left">
                      <FramedSVG
                        className="icon"
                        src={iconHospital}
                      />
                    </TextBlock>
                  </Col>
                  <Col xl={10} lg={10} md={10} xs={9}>
                    <TextBlock align="left">
                      <TextBlock.Outline className="feature-headline">
                        {t('showcase.KIS.feature1.headline')}
                      </TextBlock.Outline>
                      <TextBlock.Copy>
                        {t('showcase.KIS.feature1.body')}
                      </TextBlock.Copy>
                    </TextBlock>
                  </Col>
                </Row>
                <Row>
                  <Col xl={2} lg={2} md={2} xs={3}>
                    <TextBlock align="left">
                      <FramedSVG
                        className="icon"
                        src={iconFire}
                      />
                    </TextBlock>
                  </Col>
                  <Col xl={10} lg={10} md={10} xs={9}>
                    <TextBlock align="left">
                      <TextBlock.Outline className="feature-headline">
                        {t('showcase.KIS.feature2.headline')}
                      </TextBlock.Outline>
                      <TextBlock.Copy>
                        {t('showcase.KIS.feature2.body')}
                      </TextBlock.Copy>
                    </TextBlock>
                  </Col>
                </Row>
                <Row>
                  <Col xl={2} lg={2} md={2} xs={3}>
                    <TextBlock align="left">
                      <FramedSVG
                        className="icon"
                        src={iconNetwork}
                      />
                    </TextBlock>
                  </Col>
                  <Col xl={10} lg={10} md={10} xs={9}>
                    <TextBlock align="left">
                      <TextBlock.Outline className="feature-headline">
                        {t('showcase.KIS.feature3.headline')}
                      </TextBlock.Outline>
                      <TextBlock.Copy>
                        {t('showcase.KIS.feature3.body')}
                      </TextBlock.Copy>
                    </TextBlock>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Element>
        <Element name="get-in-touch-bottom">
          <Container className="mb40 get-in-touch">
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <TextBlock className="cta">
                  <FramedSVG
                    className="rocket-icon"
                    src={iconRocket}
                  />
                  <h3 className="">{t('getInTouch.headline')}</h3>
                  <TextBlock.Outline>
                    {t('getInTouch.cta')}
                  </TextBlock.Outline>
                  <h1 className="email">
                    <RouterLink className="email" to="mailto:info@k3b.de">
                      info@k3b.de
                    </RouterLink>
                  </h1>
                </TextBlock>
              </Col>
            </Row>
          </Container>
        </Element>
      </div>
    </>
  );
};

Home.Footer = () => (
  <Container className="home-footer mt50">
    <Row>
      <Col className="icon-reference">
        Icons made by
        <ul className="pl20">
          <li><RouterLink href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</RouterLink></li>
          <li><RouterLink href="https://www.flaticon.com/authors/geotatah" title="geotatah">geotatah</RouterLink></li>
          <li><RouterLink href="https://www.flaticon.com/authors/itim2101" title="itim2101">itim2101</RouterLink></li>
          <li><RouterLink href="https://www.flaticon.com/authors/monkik" title="monkik">monkik</RouterLink></li>
          <li><RouterLink href="https://www.flaticon.com/authors/mynamepong" title="mynamepong">mynamepong</RouterLink></li>
          <li><RouterLink href="https://www.flaticon.com/authors/srip" title="srip">srip</RouterLink></li>
          <li><RouterLink href="https://www.flaticon.com/authors/those-icons" title="Those Icons">Those Icons</RouterLink></li>
          <li><RouterLink href="https://www.flaticon.com/authors/xnimrodx" title="xnimrodx">xnimrodx</RouterLink></li>
        </ul>
        from&nbsp;
        <RouterLink href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</RouterLink>
      </Col>
    </Row>
  </Container>
);

export default Home;
