import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { scrollToTop } from '../asset/script/scrollTo';

import NavMenu from '../component/NavMenu';
import './Footer.scss';


const Footer = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div className="footer mb40">
      <Container fluid className="bottom-nav">
        <div className="scroll-to-top">
          <FontAwesomeIcon
            icon={faChevronUp}
            className="chevron-up scroll-to-top-icon"
            onClick={scrollToTop}
          />
        </div>
        <hr className="line" />
        <NavMenu>
          <NavMenu.Item label={t('navmenu.home')} url="" />
          <NavMenu.Item label={t('navmenu.imprint')} url="imprint" />
        </NavMenu>
      </Container>
      {children}
    </div>
  );
};

Footer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Footer.defaultProps = {
  children: null,
};

export default Footer;
